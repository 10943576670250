.section-tracker-container {
  display: flex;
  align-self: flex-end;
  position: fixed;
  // background-color: black;
  height: 100%;
  width:4rem;
  margin-right: 2rem;
  flex-direction: column;
  z-index: 5;
  place-content: center;
  text-align: center;

  @media screen and (max-width: $tablet){
    margin-right: -1rem;
  }

  .section-circle {
    font-weight: bold;
    margin: 1rem;
    padding: 1rem;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s;

    @media screen and (max-width: $tablet){
      width: 3px;
      height: 3px;
      padding: 3px;
    }

    &.color-transition {
      border: 2px solid var(--fontColor);
    }

    &:hover {
      font-weight: bold;
      color: var(--backgroundColorTo);
      background-color: var(--fontColor);
    }

    &.active {
      color: var(--backgroundColorTo);
      background-color: var(--fontColor);
    }
  }
}