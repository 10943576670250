@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

@import 'breakpoints.scss';

@import 'components/AboutMe.scss';
@import 'components/SectionTracker.scss';
@import 'components/Work.scss';
@import 'components/Projects.scss';

body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
}

canvas {
  position: absolute;
}

.portfolio-container {
  background-color: var(--backgroundColorFrom);
  color: var(--fontColor);
  z-index: 10;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  -webkit-transition: background-color 0.3s linear, color 0.3s linear;
  -ms-transition: background-color 0.3s linear, color 0.3s linear;
  transition: background-color 0.3s linear, color 0.3s linear;

  &.color-transition {
    background-color: var(--backgroundColorTo);
  }

  .canvas-wrapper {
    div {
      position: absolute;
    }
  }
}