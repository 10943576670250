.work-anime-wrapper {
  height: 100vh;
  width: max-content;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;

  &.active {
    visibility: visible;
    opacity: 1;
  }

  @media screen and (max-width: $phone) {
    width: 100%;
  }

  .work-container {
    justify-content: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 4rem;
    position: fixed;
    z-index: 100;

    @media screen and (max-width: $tablet) {
      width: 80%;
    }

    @media screen and (max-width: $phone) {
      margin-left: 3rem;
    }

    .work-experience-section {
      .work-slider {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 30rem;
        font-size: 1.5rem;

        .fas {
          z-index: 1;
          padding: 1rem;
          margin: 0 1rem;
          cursor: pointer;
          opacity: 0.6;
          transition: opacity 0.3s;

          &:hover {
            opacity: 1;
            transition: opacity 0.3s;
          }

          &.inactive {
            opacity: 0;
            cursor: auto;
          }
        }

        @media screen and (max-width: $tablet) {
          width: 100%;
        }

        @media screen and (max-width: $phone) {
          font-size: 1rem;
        }

        b {
          z-index: 0;
          width: 100%;
          text-align: center;
          visibility: hidden;
          position: absolute;
          opacity: 0;
          transition: all 0.2s;

          &.active {
            transition: all 0.2s 0.2s;
            opacity: 1;
            visibility: visible;
          }
        }
      }

      .current-work {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-bottom: 1rem;

        .work-circle {
          font-weight: bold;
          margin: 1rem;
          padding: 0.25rem;
          height: 3px;
          width: 3px;
          border-radius: 50%;
          border: 2px solid var(--fontColor);
          cursor: pointer;
          transition: all 0.3s;
      
          @media screen and (max-width: $tablet){
            padding: 3px;
            margin: 0.25rem;
          }
      
          &.color-transition {
            border: 2px solid var(--fontColor);
          }
      
          &:hover {
            font-weight: bold;
            color: var(--backgroundColorTo);
            background-color: var(--fontColor);
          }
      
          &.active {
            color: var(--backgroundColorTo);
            background-color: var(--fontColor);
          }
        }
      }

      .work-experience {
        display: flex;
        flex-direction: column;
        font-size: 1.25rem;
        width: 30rem;
        height: 10rem;
        position: absolute;
        visibility: hidden;
        opacity: 0;
        transition: all 0.2s;

        ul {
          margin-left: -2.5rem;
        }

        &.active {
          transition: all 0.2s 0.2s;
          opacity: 1;
          visibility: visible;
        }

        ul {
          font-size: 1rem;
        }

        @media screen and (max-width: $tablet) {
          font-size: 1rem;
          width: 100%;
        }
      }
    }

    #work {
      margin-top: -5rem;
    }
  
    .title {
      font-weight: bold;
      font-size: 7rem;

      @media screen and (max-width: $phone) {
        font-size: 3rem;
      }
    }
  
    .sub-title {
      font-size: 2rem;
    }
  
    .links {
      display: flex;
      margin-top: 2rem;
  
      a {
        display: flex;
        flex-direction: row;
        margin-right: 2rem;
        text-decoration: none;
        color: var(--fontColor);
        -webkit-transition: color 0.3s linear;
        -ms-transition: color 0.3s linear;
        transition: color 0.3s linear;
  
        span {
          font-size: 1.5rem;
          align-self: center;
          margin-left: 0.5rem;
          color: var(--fontColor);
          -webkit-transition: color 0.3s linear;
          -ms-transition: color 0.3s linear;
          transition: color 0.3s linear;
        }
  
        &:hover {
          color: #C5C6C7;
  
          i {
            transform: scale(1.4);
          }
        }
  
        i {
          font-size: 1.5rem;
          transition: all linear 0.2s;
          align-self: center;
        }
      }
    }
  }

  .anime-container {
    height: 100vh;
  }
}
