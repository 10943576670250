.aboutme-anime-wrapper {
  height: 100vh;
  width: max-content;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  width: 100%;

  &.active {
    visibility: visible;
    opacity: 1;
  }

  .about-me-container {
    justify-content: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 4rem;
    position: absolute;
    z-index: 100;

    &.hidden {
      opacity: 0.1;
    }

    @media screen and (max-width: $phone) {
      margin-left: 3rem;
    }
  
    .title {
      font-weight: bold;
      font-size: 7rem;

      @media screen and (max-width: $phone) {
        font-size: 3rem;
      }
    }
  
    .sub-title {
      font-size: 2rem;
    }
  
    .links {
      display: flex;
      margin-top: 2rem;

      @media screen and (max-width: $phone) {
        flex-direction: column;
      }
  
      a {
        display: flex;
        flex-direction: row;
        margin-right: 2rem;
        text-decoration: none;
        color: var(--fontColor);
        -webkit-transition: color 0.3s linear;
        -ms-transition: color 0.3s linear;
        transition: color 0.3s linear;
  
        span {
          font-size: 1.5rem;
          align-self: center;
          margin-left: 0.5rem;
          color: var(--fontColor);
          -webkit-transition: color 0.3s linear;
          -ms-transition: color 0.3s linear;
          transition: color 0.3s linear;
        }
  
        &:hover {
          color: #C5C6C7;
  
          i {
            transform: scale(1.4);
          }
        }
  
        i {
          font-size: 1.5rem;
          transition: all linear 0.2s;
          align-self: center;
        }
      }
    }
  }

  .anime-container {
    height: 100vh;
  }
}
